import React from "react";
import './Footer.css'
import telegram from "./images/telegram.svg"
import twitter from "./images/square-twitter.svg"

const Footer = () => {

    const goToTelegram = () => {
        window.open("https://t.me/defidomainscommunity", "_blank");
    };

    const goToTwitter = () => {
        window.open("https://twitter.com/defidomains", "_blank");
    }
    
    return (
        <div className="footerContainer">
            <div className="footerLeft">
                <div>© 2023 Darwins, Inc.</div>
                <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                <a className='pointer' href="/">Home</a>{" "}
                <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                <a className='pointer' href="/privacy-policy">Privacy Policy</a>{" "}
            </div>
            <div className="footerRight">
                <div className="twitterIconContainer" onClick={goToTwitter}>
                    <img className="twitter" src={twitter} alt="" />
                </div>
                <div className="telegramIconContainer" onClick={goToTelegram}>
                    <img className="telegram" src={telegram} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Footer;