import React from "react"
import './Info.css'
import wrench from './images/wrench-solid.svg'
import globe from './images/globe-solid.svg'
import infinity from './images/infinity-solid.svg'
import users from './images/users-solid.svg'

const Info = () => {
    return (
        <div className="infoContainer">
            <div className="infoTitle">
                <div>Why Get a .defi Domain?</div>
            </div>

            <div className="infoBody">
                <div className="infoSection">
                    <div className="sectionImageContainer">
                        <img className="sectionIcon" src={wrench} alt="" />
                    </div>
                    <div className="sectionTitle">Build The Future</div>
                    <p>
                        .defi domains are succinct, relevant, and timely for the booming decentralized finance industry
                    </p>
                </div>
                <div className="infoSection">
                    <div className="sectionImageContainer">
                        <img className="sectionIcon" src={infinity} alt="" />
                    </div>
                    <div className="sectionTitle">Unstoppable</div>
                    <p>
                        The Handshake network facilitates unstoppable, permanent domains free from ICANN regulation
                    </p>
                </div>
                <div className="infoSection">
                    <div className="sectionImageContainer">
                        <img className="sectionIcon" src={users} alt="" />
                    </div>
                    <div className="sectionTitle">High Availability</div>
                    <p>
                        Be early and get you choice of a high avialability of domains using the .defi TLD
                    </p>
                </div>
                <div className="infoSection">
                    <div className="sectionImageContainer">
                        <img className="sectionIcon" src={globe} alt="" />
                    </div>
                    <div className="sectionTitle">Web 3.0 Compatibility</div>
                    <p>
                        Route blockchain wallets through .defi domains and create human-readable addresses
                    </p>
                </div>
            </div>
        </div>
    )
} 

export default Info