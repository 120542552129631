import React from "react";
import './Telegram.css'

const Telegram = () => {

    const goToTelegram = () => {
        window.open("https://t.me/defidomainscommunity", "_blank");
    };

    return (
        <div className="telegramContainer">
            <div className="telegramTitle">
                Join Our Telegram Channel
            </div>
            <div className="telegramCaption">
                Get news and updates right on our channel!
            </div>
            <div onClick={goToTelegram} className="telegramButton">TELEGRAM CHANNEL</div>
        </div>
    )
}

export default Telegram