import React, {useState} from "react";
import "./Faq.css"
import Section from "./Section";

interface Desc {
    question: string,
    answer: string,
    open: boolean
}

const Faq = () => {
    const [faq, setFaq] = useState<Desc[]>([
        {
            question: "What is the .defi Top-Level Domain?",
            answer: "The .defi top-level domain is a decentralized root name enabled and created by the Handshake network.",
            open: false
        },
        {
            question: "What is the Handshake Network?",
            answer: "Handshake is a UTXO-based blockchain protocol which manages the registration, renewal and transfer of DNS top-level domains (TLDs).  Its purpose is not to replace DNS, but to replace the root zone file and the root servers.",
            open: false
        },
        {
            question: "What is DeFi?",
            answer: "Decentralized Finance (DeFi) or Open Finance is a system of decentralized blockchains and smart contracts that provide historically institutional financial services over the web. Imagine a global, open alternative to every financial service you use today — savings, loans, trading, insurance and more — accessible to anyone in the world with a smartphone and internet connection.",
            open: false
        },
        {
            question: "What Do I Need to Get Started?",
            answer: "Unlike most TLDs, which are regulated and managed by the Internet Corporation for Assigned Names and Numbers, Handshake TLDs are managed and assigned by the Handshake blockchain.",
            open: false
        },
        {
            question: "What Do I  Find a .defi Domain?",
            answer: "While early browser support is still nascent, using tools like HNS.to (or the box above) can convert .defi, along with any other Handshake domain, to  a browser-routable format.",
            open: false
        },
        {
            question: "Should I Hoard Trademarked Domain Names?",
            answer: "No. While we can’t stop you, conventional legal action can be taken by the trademark owner. Trademarks are protected by both federal and state laws, and in case of a legal conflict with a later user, the first commercial user of a trademark owns it.",
            open: false
        }
    ]);

    const toggleFAQ = (index: any) => {
        setFaq(
            faq.map((entry, i) => {
                if(i === index) {
                    entry.open = !entry.open;
                }else {
                    entry.open = false;
                }
                return entry;
            })
        )
    }

    return (
        <div className="faqContainer">
            <div className="faqTitle">
                Frequently Asked Questions
            </div>
            <div className="faqs">
                {faq.map((faq, index) => (
                <Section faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
            </div>
        </div>
      );
}

export default Faq;