import React from "react";
import './Privacy.css'
import defi from "./images/defi TLD Light SVG.svg"
import Footer from "../Footer/Footer";
const Privacy = () => {
    return (
        <div>
            <div className="privacyContainer">
                <div className="privacyLogo">
                    <img className="privacyImage" src={defi} alt="" />
                </div>
                <div className="privacyTitle">
                    Privacy Policy
                </div>
                <div className="privacyBody">
                    <p>
                        Darwins Inc. and its affiliates (collectively, “Darwins Inc.,”, “defi.me.,” “we,” “us,” or “our”) provide this privacy and security policy (“Privacy Policy”) because we know that you care about how your information is collected, used, shared and retained. This Privacy Policy applies only to information collected when you access our website (defi.me) and our Mobile Site (collectively the “defi.me Site”) or use the services that we make available via the defi.me Site from time to time (the “Services”). Our Services may include offering you the opportunity to post information, quotes, comments, data, text, graphics and other materials on the defi.me Site (“User Content”), become a registered user, purchase products via the defi.me Site, or use our Mobile Apps. By visiting the defi.me Site or using the Services, you accept the terms of this Privacy Policy.
                    </p>
                    <p className="privacyBold">
                        I. Safe Harbor Certification
                    </p>
                    <p>
                        In light of the international nature of our business, Darwins Inc. complies with the U.S.-EU Safe Harbor Framework and the U.S.-Swiss Safe Harbor Framework as set forth by the US Department of Commerce regarding the collection, use and retention of personal information from European Union member countries and Switzerland. For more information about the Safe Harbor program, please visit http://www.export.gov/safeharbor/
                    </p>
                    <p className="privacyBold">
                        II. The Information We Collect
                    </p>
                    <p>
                        Darwins Inc. collects the following types of information via the defi.me Site directly and indirectly through our service providers and advertising partners (“Service Providers”).
                    </p>
                    <p className="privacyBold">
                        III. Personal Information
                    </p>
                    <p>
                        Personal Information means any information that may be used to identify a natural person. Generally, you may visit and browse the defi.me Site without revealing any of your Personal Information. When you use the Services, you may be required to provide Personal Information. For example, if you subscribe to a mailing list, participate in special offers, sweepstakes or other promotions, join our loyalty program, send questions or comments to us via e-mail, post User Content on the defi.me Site, our Facebook page or other social media site, or purchase products via the defi.me Site, we will collect Personal Information which will be used to fulfil your order and for other purposes consistent with this Privacy Policy. If you visit the defi.me Site, or register on the defi.me Site, through Facebook Connect or another social media website, we may also receive certain Personal Information from those social media sites. From time to time we may collect or purchase demographic information (such as gender or age) or information about your interests and preferences. If we link any demographic or preferences information to your Personal Information, then all such information will be treated as Personal Information under this Privacy Policy.
                    </p>
                    <p>
                    In order to use some features provided on our defi.me Site, you may submit Personal Information about other individuals. For example, e-mailing an article from the defi.me Site or providing product information to a friend requires an email address, and completing a purchase sent directly to a friend requires a name and address. Darwins Inc. will use and store such Personal Information to fulfill your request, and for marketing and other purposes consistent with this Privacy Policy.
                    </p>
                    <p className="privacyBold">
                    IV. Non-Personal Information
                    </p>
                    <p>
                    Activity Information. We may collect your IP address, Internet service provider, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, sections of the defi.me Site visited, number of links you click while on the defi.me Site, search terms, operating system, website traffic and related statistics, keywords and/or other data.

                    Geo-locational Information. We may collect information regarding your current location (e.g., by IP address, by latitude/longitude, or zip code) when you visit the defi.me Site or use the Services. In order to provide certain Services while you are visiting our mobile site or using our Mobile Apps, we may, with your consent, automatically collect geo-locational information from your mobile device, wireless carrier or third-party service providers. Your mobile device also permits you to turn off the location services. Please be sure to manage your mobile device and privacy preferences for the Services on a consistent basis.
                    </p>
                    <p className="privacyBold">
                    V. Cookies and Other Tracking Technologies
                    </p>
                    <p>
                    When you visit the defi.me Site, cookies or other tracking technologies may be placed on your computers or mobile devices. A cookie is a small amount of information that a web server sends to your browser that stores Non-Personal Information about your account, preferences and use of the defi.me Site and Services. Cookies allow us to recognize you when you return to the defi.me. Site and track and target your interests in order to provide a customized experience. They also help us to detect certain kinds of fraud. We use cookies to store preferences, record session information, record user-specific information on what pages are accessed or visited, alert you to new areas of the defi.me Site, or customize web page content, services and advertisements based on browser type or other information that the tracking technologies provide.
                    </p>
                    <p>
                    “Session” cookies are temporary cookies used for various reasons, such as managing page views and your shopping cart. Typically, your browser will erase session cookies once you exit the browser. “Persistent” cookies are more permanent cookies that are stored on your computer or mobile device after you exit the browser. Persistent cookies allow us to retrieve certain information that you have previously provided to us (for example, your user ID if you ask to be remembered). The defi.me Site may use both session and persistent cookies.
                    </p>
                    <p>
                    The defi.me Site may also use “tracer tags,” which are tiny graphic images placed on defi.me Site pages or in our e-mails that allow us to determine whether you have performed certain actions. When you access these pages or open e-mail messages, the tags generate a notice of that action to us or our Service Providers. These tools allow us to measure response to our communications and improve our web pages and promotions.
                    </p>
                    <p>
                    You may manage, block or delete cookies and other tracking technologies by adjusting your browser’s security and privacy settings but, if you do so, you may not be able to use particular features of the defi.me. Site or the Services.
                    </p>
                    <p className="privacyBold">
                    VI. How We Use Your Information
                    </p>
                    <p>
                    We may use your Personal Information in a manner that is consistent with this Privacy Policy. If you provide Personal Information for a certain reason, we may use such Personal Information in connection with the reason it was provided. For instance, we may use Personal Information to process purchases via the defi.me Site; administer, improve and provide access to the defi.me Site and the Services; respond to inquiries; upload User Content that may include Personal Information; or to communicate with you by email, telephone or regular mail regarding news updates, special promotional offers, our products and services, or other information which may be of interest to you. If you later wish to opt-out of receiving such promotional communications from us at any time, simply follow the “Opt-Out” instructions.
                    </p>
                    <p>
                    Darwins Inc. may link or combine Personal Information with other personally identifiable information (such as transaction history) and Non-Personal Information that is available from our records and other sources. We may use this combined information for marketing and advertising programs, to enforce defi.me Site policies, protect the defi.me Site, our customers and others, and to comply with applicable law.
                    </p>
                    <p className="privacyBold">
                    VII. Sharing Your Information
                    </p>
                    <p>
                    Darwins Inc. may share your Personal Information and Non-Personal Information with third parties without notice to you under certain circumstances including
                    </p>
                    <p>
                    Service Providers
                    </p>
                    <p>
                    When we engage a Service Provider to perform certain business-related functions, we only provide them with the information that they need to perform their specific function. We may also share your information with any of our parent companies, subsidiaries, or other companies under common control with us.
                    </p>
                    <p>
                    Legal Requirements
                    </p>
                    <p>
                    We may disclose your information if required to do so by law, or in the good faith belief that such action or disclosure is necessary or appropriate (i) to operate the defi.me Site, (ii) comply with any legal obligation, report unlawful activity, cooperate with law enforcement, or protect against legal liability, (iii) protect and defend our rights, property, personnel, suppliers, sponsors, agents or licensors, or (iv) protect the personal safety of users of the defi.me Site or the public.
                    </p>
                    <p>
                    Business Transfers
                    </p>
                    <p>
                    We may share your information with business partners. In the event of a corporate sale, asset sale, merger, reorganization, dissolution or similar event, Personal Information, and Non-Personal Information may be part of the assets that are transferred.
                    </p>
                    <p>
                    Aggregated Information
                    </p>
                    <p>
                        We may aggregate Personal Information and Non-Personal Information and provide it to our existing or potential business partners, sponsors, advertisers or other third parties, in response to a government request, or to other third parties for lawful purposes.
                        Darwins Inc. may disclose Personal Information to third party marketers (who may combine such Personal Information with their own records, and records available from other sources), for their own direct marketing purposes, and the direct marketing purposes of other third-party marketers. If you wish to opt-out of sharing your Personal Information with third party marketers for their own and third party direct marketing purposes, please unsubscribe.
                    </p>
                    <p className="privacyBold">
                        VIII. User Content
                    </p>
                    <p>
                    We are not responsible for protecting any Personal Information or User Content you post on the defi.me Site or on social media web pages associated with defi.me. Any Personal Information or User Content posted on the defi.me Site or associates social media pages is publicly available, unprotected and may be viewed by anyone with access to the specific website.
                    </p>
                    <p>
                    User Content or Personal Information published on the defi.me Site or associated social media sites may also appear in search engine results (such as Yahoo!, MSN, Google, AltaVista, and other search engines) and in the cache of those search engines, in feeds and third-party websites pursuant to cobranding agreements. We have no control over updating and/or removal of such User Content or Personal Information, which is the sole responsibility of the search engines, third party websites and RSS web feed resources. You agree and acknowledge that Darwins Inc. is not liable for the information published in search results or by any third-party website that carries any User Content or Personal Information published on the defi.me Site or associated social media sites. We strongly discourage posting any Personal Information or User Content that you do not want others to see or use.
                    </p>
                    <p>
                    Darwins Inc. reserves the right, but has no obligation, to monitor User Content and Personal Information posted on the defi.me Site. You acknowledge that by providing you with the ability to post, view and distribute User Content and Personal Information on the defi.me Site, Darwins Inc. is merely acting as a passive conduit for such distribution and is not undertaking any obligation or liability relating thereto. We reserve the right, but have no obligation, to block or remove communications, User Content, Personal Information or other submissions to the defi.me Site, for any reason or no reason, in our sole and absolute discretion.
                    </p>
                    <p className="privacyBold">
                    IX. Advertising
                    </p>
                    <p>
                    Darwins Inc. may, directly or indirectly through Service Providers, serve personalized advertisements for Darwins Inc. products, services and programs when you visit the defi.me Site and other sites. Darwins Inc. and Service Providers may use Personal Information and Non-Personal Information collected during your visits to the defi.me Site to select advertisements, perform, or have performed, cookie synchs, and otherwise combine and compare your information with other databases for Darwins Inc.’s commercial purposes. You may opt-out of the tailoring of advertising based on information we collect.
                    </p>
                    <p>
                    X. Opt-Out
                    </p>
                    <p>
                    You may opt-out of receiving promotional emails from us regarding new features, services and special offers when registering for an account by unchecking the box that asks whether you would like to receive e-mail updates.
                    </p>
                    <p>
                    You may unsubscribe from receiving promotional e-mails of certain types (or a company-wide unsubscribe) at any time by using the Unsubscribe page on the defi.me Site. Click here to unsubscribe. Please pay careful attention to the various options for unsubscribing. You may have the option to unsubscribe from one or more special interest lists or unsubscribe from all promotional e-mails.
                    </p>
                    <p>
                        When you receive a promotional e-mail from Darwins Inc., it will contain a link that allows you to unsubscribe. If you decide to use this opt-out link, you will no longer receive special offers, valuable coupons, new product introductions and informational health and wellness newsletters from Darwins Inc. via email or direct mail; however, you will continue to receive non-promotional e-mails and direct mail from us, such as order confirmations.
                    </p>
                    <p className="privacyBold">
                    XI. Security
                    </p>
                    <p>
                    Darwins Inc. takes reasonable precautions to safeguard your Personal Information against unauthorized access or disclosure including Secure Sockets Layer (SSL) encryption. Unfortunately, no data transmission over the Internet or stored on a server can be guaranteed to be 100% secure. As a result, we cannot guarantee or warrant the security of any information you disclose or transmit to us online, and Darwins Inc. is not responsible for the theft, destruction, or inadvertent disclosure of your Personal Information.
                    When creating an account on our defi.me Site, you are required to establish a password. To maximize your level of protection, you should choose a password with at least 6 characters, using a combination of letters and numbers. We recommend that you not disclose your password to any other person. You are solely responsible for maintaining the secrecy of your password and account information, and for all activities that occur under your password or account.
                    </p>
                    <p>
                    Credit card and other financial information that is necessary to complete purchases on the defi.me Site is collected and processed by a Service Provider and encrypted using SSL before transmitted over the Internet.
                    </p>
                    <p className="privacyBold">
                    XII. Accessing and Correcting Personal Information
                    </p>
                    <p>
                    You can access and correct your Personal Information by logging into your account and accessing the “My Account” section of the defi.me Site. If you wish to cancel your account, please click here to unsubscribe. If you also wish to opt-out of receiving emails from us after your account is cancelled, please follow the steps described under “Opt-Out.” We may retain your Personal Information and Non-Personal Information indefinitely after termination of your account for use in compliance with this Privacy Policy.
                    </p>
                    <p className="privacyBold">
                    XIII. Children
                    </p>
                    <p>
                    The defi.me Site is not intended for use by children under the age of 13. Darwins Inc. does not knowingly collect or use any Personal Information from such children. If you are under the age of 13, do not submit any information to this defi.me Site. If we learn that we collected Personal Information from children under the age of 13, we will take steps to delete that information as soon as possible.
                    </p>
                    <p className="privacyBold">
                    VIX. Changes to Privacy Policy
                    </p>
                    <p>
                    Darwins Inc. may, in its sole discretion, change this Privacy Policy from time to time without prior notice. We will notify you of any material changes to this Privacy Policy by changing the “Last Updated” date at the top of this Privacy Policy. If you visit the defi.me Site or use the Services after a change to this Privacy Policy is posted on the defi.me Site, you will be bound by such change. Please refer back to this Privacy Policy on a regular basis. If you object to any of the terms in a revised Privacy Policy, you have the right to cease using the defi.me Site and the Services. If you have questions about this Privacy Policy or believe that Darwins Inc. has acted inconsistently with this Privacy Policy, please Contact Us.
                    </p>
                    <p className="privacyBold">
                        XV. Privacy Practices of Third Parties
                    </p>
                    <p>
                        The defi.me Site may contain links to third party websites, products, and services, including social media sites such as Facebook and Twitter. These third-party websites may place cookies or other tracking technologies on your computer or personal device. Information collected by these third-party websites is governed by their privacy practices and is not subject to this Privacy Policy. We are not responsible for how these third-party websites collect, use, disclose, distribute, or maintain Personal and Non-Personal Information. If you visit a third-party website linked from the defi.me Site, you do so at your own risk. We encourage you to learn about the privacy practices of those third parties prior to providing them with an information or taking advantage of an offer or promotion. Darwins Inc. expressly disclaims any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of Personal Information by third parties
                    </p>
                    <p className="privacyBold">
                        XVI. Governing Law
                    </p>
                    <p>
                        This Privacy Policy and the privacy practices of Darwins Inc. will be subject exclusively to the laws of the State of Delaware, United States
                    </p>
                    <p className="privacyBold">
                        XVII. Important Notice to Non-U.S. Residents/Safe Harbor Certification
                    </p>
                    <p>
                        Darwins Inc. abides by the principles set forth in the U.S.­EU Safe Harbor Framework and the U.S.­ Swiss Safe Harbor Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of data from the European Union member countries and Switzerland. However, it is important to note that the servers that store the information are operated in the United States or at one of our other data centers located outside the United States. If you are located outside of the United States, please be aware that any information provided to us may be transferred to the United States. By visiting the defi.me Site(s) and/or using our Services, you consent to this transfer.
                    </p>
                    <p>
                        In order to comply with the Safe Harbor framework, Darwins Inc. commits to the resolution of complaints about your privacy and our collection or use of the information. We have committed to resolve any complaints by European Union citizens relating to this Privacy Policy, which cannot be resolved directly with our company, through the Council of Better Business Bureau’s Safe Harbor Privacy Dispute Resolution Procedures.
                    </p>
                    <p>
                        If you have any questions regarding this Privacy Policy, please contact us via the contact form on this website. We will investigate your question, respond to your inquiry, and attempt to resolve any concerns regarding your privacy question. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed by us, please visit the BBB EU SAFE HARBOR web site at www.bbb.org/us/safe­harbor­complaints for more information and to file a complaint.
                    </p>
                    <p className="privacyBold">
                        XVIII. California Privacy Rights
                    </p>
                    <p>
                        Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of Personal Information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. To submit a request pursuant to Section 1798.83, please contact Darwins Inc. via our feedback form.
                    </p>
                </div>  
            
            </div>
            <Footer />
        </div>
        
    )
}

export default Privacy;