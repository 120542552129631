import React from "react";
import "./Registrar.css"
import encirca from "./images/encirca.png"
import namecheap from "./images/namecheap.png"

const Registrar = () => {

    const goToNamecheap = () => {
        window.open("https://www.namecheap.com/", "_blank");
    };

    const goToEncirca = () => {
        window.open("https://encirca.com/", "_blank");
    }


    return (
        <div className="registrarContainer">
            <div className="registrarTitle">
                <div>Available at These Registrars</div>
            </div>

            <div className="registrarBody">
                <div className="registrarSection">
                    <div className="registrarSectionImageContainer">
                        <img className="registrarImage" src={namecheap} alt="" onClick={goToNamecheap}/>
                    </div>
                </div>
                <div className="registrarSection">
                    <div className="registrarSectionImageContainer">
                        <img className="registrarImage" src={encirca} alt=""  onClick={goToEncirca}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registrar