import React from 'react';
import './Landing.css';
import defi from './images/defi TLD Light SVG.svg'
import globe from './images/better_globe.png'

const Landing = () => {

    const goToSite = () => {
        window.open("https://www.namecheap.com/", "_blank");
      };

    return (
        <div className="landingContainer">
            <div className='logoContainer'>
                <img className='logo' src={defi} alt="defi" />
            </div>
            
            <div className='slogan'>
                <div className='blue'>
                    Decentralized Finance
                </div>
                <div>
                    Finally Has a Home
                </div>
            </div>

            <div className='caption'>
                Build Decentralized Finance on a <span>.defi</span> Domain
            </div>

            <button className='buy' onClick={goToSite}>
                Buy Now!
            </button>

            <div className='globeContainer'>
                <img className='globe' src={globe} alt="" />
            </div>
            
        </div>
    )
}

export default Landing;