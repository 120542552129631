import React from 'react';
import './App.css';
import Home from './components/Home/Home';
import Privacy from './components/Privacy/Privacy';
// import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// const TRACKING_ID = "G-7V9ZQX9RZE"; // This is in our project in GA4
// ReactGA.initialize(TRACKING_ID, {debug: true});

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/privacy-policy" element={<Privacy/>}/>
        </Routes>
      </Router>
    </div>
   
  );
}

export default App;
