import React from 'react';
import './Home.css'
import Landing from '../Landing/Landing';
import Info from '../Info/Info';
import Link from '../Link/Link';
import Faq from '../Faq/Faq';
import Registrar from '../Registrar/Registrar';
import Telegram from '../Telegram/Telegram';
import Footer from '../Footer/Footer';

const Home = () => {
    return (
        <div>
            <div className='container'>
                <Landing />
                <Info/>
                <Link />
                <Faq />
                <Registrar />
            </div>
            <Telegram />
            <Footer />
        </div>
        
    )
}

export default Home