import React, {useState} from "react";
import './Link.css';

const Link = () => {

    const [link, setLink] = useState("");

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        console.log("link", link)
        window.open('http://'+link+".hns.to", '_blank');
    }

    return (
        <div className="linkContainer">
            <div className="linkTitle">
                <div>
                    View any .defi website here!
                </div>
            </div>
            <div className="linkBody">
                <form className="linkForm" onSubmit={handleSubmit}>
                    <input className="linkType" type="text" placeholder="Enter a .defi website" value={link} onChange={e => setLink(e.target.value)}/>
                    <input className="linkSubmit" type="submit" value="Go"/>
                </form>
            </div>
        </div>
    )
}

export default Link;